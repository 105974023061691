import React, { useEffect, useState } from 'react';
import Dummy from './Dummy';
import './main.scss';
import ControlCard from './Cards/ControlCard/ControlCard';
import Button from './Common/Buttons/Button/Button';
import Header from './Header';
import Snackbar from './Common/Snackbar/Snackbar';

export const DarkMode = React.createContext('--light');

export default function App() {
	const [allCornerChange, setAllCornerChange] = useState(false);
	const [showSnackbar, setShowSnackbar] = useState(false);
	const [elementCount, setElementCount] = useState(1);
	const [layout, setLayout] = useState('grid');
	const [direction, setDirection] = useState('column');
	const [columns, setColumns] = useState(1);
	const [color1, setColor1] = useState('#8976FF');
	const [color2, setColor2] = useState('#F476FF');
	const [widthHeightLock, setWidthHeightLock] = useState(true);
	const [units, setUnits] = useState('px');
	const [isPx, setIsPx] = useState(true);
	const [layoutChecked, setLayoutChecked] = useState(true);
	const [gradientMidpoint, setGradientMidpoint] = useState(50);
	const [gradientAngle, setGradientAngle] = useState(90);
	const [effectiveMidpoint, setEffectiveMidpoint] = useState(100);
	const [fillIsGradient, setFillIsGradient] = useState(true);
	const [borderWidth, setBorderWidth] = useState(0);
	const [borderColor, setBorderColor] = useState('#F476FF');
	const [darkMode, setDarkMode] = useState(false);
	const [theme, setTheme] = useState(darkMode ? '--dark' : '--light');
	const [gridGap, setGridGap] = useState({
		rowGap: 8,
		columnGap: 8,
	});
	const [size, setSize] = useState({
		width: 120,
		height: 120,
	});
	const [showClipboardDummyInput, setShowClipboardDummyInput] = useState(
		false
	);
	const [border, setBorder] = useState({
		borderTopLeftRadius: 16,
		borderTopRightRadius: 16,
		borderBottomRightRadius: 16,
		borderBottomLeftRadius: 16,
	});

	useEffect(() => {
		let themePref = window.localStorage.getItem('theme');
		if (themePref) {
			if (themePref === 'dark') {
				setDarkMode(() => true);
			} else if (themePref === 'light') {
				setDarkMode(() => false);
			}
		}
	}, []);

	useEffect(() => {
		if (darkMode) {
			setTheme(() => '--dark');
			window.localStorage.setItem('theme', 'dark');
			document.body.style.background = '#262626';
		} else {
			setTheme(() => '--light');
			window.localStorage.setItem('theme', 'light');
			document.body.style.background = '#fafafa';
		}
	}, [darkMode]);

	useEffect(() => {
		let wid = window.innerWidth;
		if (wid <= 480) {
			setSize(() => ({
				width: 64,
				height: 64,
			}));
		}
	}, []);

	useEffect(() => {
		if (isPx) {
			setUnits('px');
		} else {
			setUnits('%');
		}
	}, [isPx]);

	const handleSetBorderRadius = (name, val) => {
		if (allCornerChange) {
			setBorder({
				...border,
				borderTopLeftRadius: val,
				borderTopRightRadius: val,
				borderBottomRightRadius: val,
				borderBottomLeftRadius: val,
			});
		} else {
			setBorder({
				...border,
				[name]: val,
			});
		}
	};

	const handleGapChange = (name, val) => {
		setGridGap({
			...gridGap,
			[name]: val,
		});
	};

	const handleSetSize = (name, value) => {
		if (widthHeightLock) {
			setSize({
				width: value,
				height: value,
			});
		} else {
			setSize({
				...size,
				[name]: value,
			});
		}
	};

	const handleSetGradientMidpoint = (name, val) => {
		setGradientMidpoint(val);
		setEffectiveMidpoint(val * 2);
	};

	const handleSetLayout = () => {
		if (layoutChecked) {
			setLayout('flex');
			setLayoutChecked(false);
		} else {
			setLayout('grid');
			setLayoutChecked(true);
		}
	};

	const mapElements = () => {
		let el = (
			<div
				style={{
					background: fillIsGradient
						? `linear-gradient(${gradientAngle}deg, ${color1} 0%, ${color2} ${effectiveMidpoint}%)`
						: `${color1}`,
					width: `${size.width}px`,
					height: `${size.height}px`,
					borderTopLeftRadius: `${border.borderTopLeftRadius}${units}`,
					borderTopRightRadius: `${border.borderTopRightRadius}${units}`,
					borderBottomRightRadius: `${border.borderBottomRightRadius}${units}`,
					borderBottomLeftRadius: `${border.borderBottomLeftRadius}${units}`,
					border: `${borderWidth}px solid ${borderColor}`,
					boxSizing: 'border-box',
				}}
			/>
		);
		let elArray = [];
		for (let i = 0; i < elementCount; i++) {
			elArray.push(el);
		}
		return elArray.map((ele, i) => (
			<React.Fragment key={i}>{el}</React.Fragment>
		));
	};

	const handleCopyCss = () => {
		let cssText = `
		.container {
			display: ${layout};
			flex-direction: ${direction};
			grid-template-columns: repeat(${columns}, 1fr);
			gap: ${gridGap.rowGap}px ${gridGap.columnGap}px;
			justify-content: center;
			align-items: center;
			height: auto;
			width: 100%;
			padding: 80px;
			box-sizing: border-box;
		}

		.block {
			background:
				${
					fillIsGradient
						? `linear-gradient(${gradientAngle}deg, ${color1} 0%, ${color2} ${effectiveMidpoint}%)`
						: color1
				};
			width: ${size.width}px;
			height: ${size.height}px;
			border-top-left-radius: ${border.borderTopLeftRadius}${units};
			border-top-right-radius: ${border.borderTopRightRadius}${units};
			border-bottom-right-radius: ${border.borderBottomRightRadius}${units};
			border-bottom-left-radius: ${border.borderBottomLeftRadius}${units};
			border: ${borderWidth}px solid ${borderColor};
			box-sizing: border-box;
		}
		`;
		setShowClipboardDummyInput(true);
		setShowSnackbar(true);
		setTimeout(() => {
			let dummy = document.getElementById('dummy-input');
			dummy.value = cssText;
			dummy.select();
			document.execCommand('copy', true);
			setShowClipboardDummyInput(false);
		}, 100);
	};

	const fields = {
		width: {
			label: 'width',
			name: 'width',
			type: 'combo',
			value: size.width,
			onSliderChange: (name, val) => handleSetSize(name, val),
			onCounterChange: (e) =>
				handleSetSize(e.target.name, e.target.value),
			max: 1000,
			min: 8,
			defaultValue: 120,
		},
		height: {
			label: 'height',
			name: 'height',
			type: 'combo',
			value: size.height,
			onSliderChange: (name, val) => handleSetSize(name, val),
			onCounterChange: (e) =>
				handleSetSize(e.target.name, e.target.value),
			max: 1000,
			min: 8,
			defaultValue: 120,
		},
		widthHeightLock: {
			label: ['free', 'lock'],
			name: 'widthHeightLock',
			type: 'toggle',
			value: widthHeightLock,
			onChange: () => setWidthHeightLock((whl) => !whl),
		},
		borderTopLeftRadius: {
			onCounterChange: (e) =>
				handleSetBorderRadius(e.target.name, e.target.value),
			onSliderChange: (name, val) => handleSetBorderRadius(name, val),
			name: 'borderTopLeftRadius',
			value: border.borderTopLeftRadius,
			max: 200,
			min: 0,
			label: 'Border Top Left Radius',
			type: 'combo',
			defaultValue: 16
		},
		borderTopRightRadius: {
			onCounterChange: (e) =>
				handleSetBorderRadius(e.target.name, e.target.value),
			onSliderChange: (name, val) => handleSetBorderRadius(name, val),
			name: 'borderTopRightRadius',
			value: border.borderTopRightRadius,
			max: 200,
			min: 0,
			label: 'Border Top Right Radius',
			type: 'combo',
			defaultValue: 16
		},
		borderBottomRightRadius: {
			onCounterChange: (e) =>
				handleSetBorderRadius(e.target.name, e.target.value),
			onSliderChange: (name, val) => handleSetBorderRadius(name, val),
			name: 'borderBottomRightRadius',
			value: border.borderBottomRightRadius,
			max: 200,
			min: 0,
			label: 'Border Bottom Right Radius',
			type: 'combo',
			defaultValue: 16
		},
		borderBottomLeftRadius: {
			onCounterChange: (e) =>
				handleSetBorderRadius(e.target.name, e.target.value),
			onSliderChange: (name, val) => handleSetBorderRadius(name, val),
			name: 'borderBottomLeftRadius',
			value: border.borderBottomLeftRadius,
			max: 200,
			min: 0,
			label: 'Border Bottom Left Radius',
			type: 'combo',
			defaultValue: 16
		},
		borderRadiusLock: {
			label: ['free', 'lock'],
			name: 'widthHeightLock',
			type: 'toggle',
			value: allCornerChange,
			onChange: () => setAllCornerChange((c) => !c),
		},
		isPx: {
			label: ['%', 'px'],
			name: 'units',
			type: 'toggle',
			value: isPx,
			onChange: () => setIsPx((p) => !p),
		},
		layout: {
			label: ['flex', 'grid'],
			name: 'layout',
			type: 'toggle',
			value: layoutChecked,
			onChange: (e) => handleSetLayout(e),
		},
		blocks: {
			label: 'blocks',
			name: 'blocks',
			type: 'counter',
			min: 1,
			value: elementCount,
			onChange: (e) => setElementCount(e.target.value),
		},
		columns: {
			label: 'columns',
			name: 'columns',
			type: 'counter',
			min: 1,
			value: columns,
			onChange: (e) => setColumns(e.target.value),
		},
		columnSpacing: {
			label: 'column spacing',
			name: 'columnGap',
			type: 'combo',
			value: gridGap.columnGap,
			min: 0,
			onCounterChange: (e) =>
				handleGapChange(e.target.name, e.target.value),
			onSliderChange: (name, val) => handleGapChange(name, val),
		},
		rowSpacing: {
			label: 'row spacing',
			name: 'rowGap',
			type: 'combo',
			value: gridGap.rowGap,
			min: 0,
			onCounterChange: (e) => handleGapChange(e.target.name, e.target.value),
			onSliderChange: (name, val) => handleGapChange(name, val),
		},
		row: {
			label: 'row',
			name: 'row',
			type: 'radio',
			value: direction === 'row',
			legend: 'flex direction',
			onChange: (e) => setDirection(e.target.value),
		},
		column: {
			label: 'column',
			name: 'column',
			type: 'radio',
			value: direction === 'column',
			legend: 'flex direction',
			onChange: (e) => setDirection(e.target.value),
		},
		solidGradient: {
			label: ['solid', 'linear'],
			name: 'backgroundType',
			type: 'toggle',
			value: fillIsGradient,
			onChange: () => setFillIsGradient((fg) => !fg),
		},
		color: {
			label: 'color',
			name: 'background1',
			type: 'color',
			value: color1,
			onChange: (e) => setColor1(e.target.value),
		},
		color2: {
			label: 'color2',
			name: 'background2',
			type: 'color',
			value: color2,
			onChange: (e) => setColor2(e.target.value),
		},
		gradientAngle: {
			label: 'angle',
			name: 'angle',
			type: 'combo',
			value: gradientAngle,
			onCounterChange: (e) =>
				setGradientAngle(e.target.value),
			onSliderChange: (name, val) => setGradientAngle(val),
			max: 360,
			min: 0,
		},
		gradientMidpoint: {
			label: 'midpoint',
			name: 'gradientMidpoint',
			type: 'combo',
			value: gradientMidpoint,
			onCounterChange: (e) =>
				handleSetGradientMidpoint(e.target.name, e.target.value),
			onSliderChange: (name, val) => handleSetGradientMidpoint(name, val),
			max: 100,
		},
		borderWidth: {
			label: 'stroke',
			name: 'borderWidth',
			type: 'combo',
			value: borderWidth,
			onCounterChange: (e) =>
				setBorderWidth(e.target.value),
			onSliderChange: (name, val) => setBorderWidth(val),
			max: 80,
			min: 0,
		},
		borderColor: {
			label: 'border color',
			name: 'borderColor',
			type: 'color',
			value: borderColor,
			onChange: (e) => setBorderColor(e.target.value),
		},
	};

	return (
		<DarkMode.Provider value={theme}>
			<div
				className={`app ${theme}`}
				style={{
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<Header
					darkMode={darkMode}
					toggleDarkMode={() => setDarkMode((d) => !d)}
				/>
				<section
					className={`main-container ${theme}`}
					style={{
						display: 'flex',
					}}
				>
					<div className={`fields ${theme}`}>
						<ControlCard
							fields={
								layout === 'grid'
									? [
											fields.layout,
											fields.blocks,
											fields.columns,
											fields.rowSpacing,
											fields.columnSpacing,
									  ]
									: [
											fields.layout,
											fields.blocks,
											fields.row,
											fields.column,
											fields.rowSpacing,
											fields.columnSpacing,
									  ]
							}
							cardLabel='size'
						/>
						<ControlCard
							fields={[
								fields.width,
								fields.widthHeightLock,
								fields.height,
							]}
							cardLabel='size'
						/>
						<ControlCard
							fields={
								fillIsGradient
									? [
											fields.solidGradient,
											fields.color,
											fields.color2,
											fields.gradientAngle,
											fields.gradientMidpoint,
									  ]
									: [fields.solidGradient, fields.color]
							}
							cardLabel='fill'
						/>
						<ControlCard
							fields={[
								fields.isPx,
								fields.borderRadiusLock,
								fields.borderTopLeftRadius,
								fields.borderTopRightRadius,
								fields.borderBottomRightRadius,
								fields.borderBottomLeftRadius,
							]}
							cardLabel='border radius'
						/>
						<ControlCard
							fields={[fields.borderWidth, fields.borderColor]}
							cardLabel='stroke'
						/>
					</div>
					<div className={`preview-container ${theme}`}>
						<div
							style={{
								display: `${layout}`,
								flexDirection: direction,
								flexWrap: 'wrap',
								gridTemplateColumns: `repeat(${columns}, auto)`,
								gap: `${gridGap.rowGap}px ${gridGap.columnGap}px`,
								justifyContent: 'flex-start',
								alignItems: 'flex-start',
								height: layout === 'flex' ? '100%' : 'auto',
								width: layout === 'flex' ? '100%' : 'auto',
								boxSizing: 'border-box',
							}}
						>
							{mapElements()}
						</div>
					</div>
					<Button
						onClick={handleCopyCss}
						label='Copy CSS'
						className='fixed-bottom-right css-butt'
					/>
					{showClipboardDummyInput && <Dummy />}
					{showSnackbar && (
						<Snackbar
							message='Copied!'
							show={showSnackbar}
							hide={() => setShowSnackbar(false)}
						/>
					)}
				</section>
			</div>
		</DarkMode.Provider>
	);
}
