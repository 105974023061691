import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import "./Counter.scss";
import TextInput from "./../TextInput/TextInput.js";
import { DarkMode } from "../../App";

const Counter = (props) => {
	const inputElement = useRef();
	const theme = useContext(DarkMode);
	const doDecrementCounter = (e) => {
		let nextValue = parseFloat(props.value || 0) - buttonStep;
		if (e.shiftKey) {
			nextValue = nextValue + buttonStep - buttonStep * 10;
		}
		if (isNaN(nextValue)) return;
		let value = Math.round(nextValue * 1000) / 1000;
		if (!!props.min || props.min === 0) value = Math.max(props.min, value);

		triggerValueChange(value);
	};

	const doIncrementCounter = (e) => {
		let nextValue = parseFloat(props.value || 0) + buttonStep;
		if (e.shiftKey) {
			nextValue = nextValue - buttonStep + buttonStep * 10;
		}
		if (isNaN(nextValue)) return;
		let value = Math.round(nextValue * 1000) / 1000;
		if (!!props.max || props.max === 0) value = Math.min(props.max, value);
		triggerValueChange(value);
	};

	const triggerValueChange = (value) => {
		const valueSetter = Object.getOwnPropertyDescriptor(
			inputElement.current,
			"value"
		).set;
		const prototype = Object.getPrototypeOf(inputElement.current);
		const prototypeValueSetter = Object.getOwnPropertyDescriptor(
			prototype,
			"value"
		).set;

		if (valueSetter && valueSetter !== prototypeValueSetter) {
			prototypeValueSetter.call(inputElement.current, value);
		} else {
			valueSetter.call(inputElement.current, value);
		}
		inputElement.current.dispatchEvent(
			new Event("input", { bubbles: true })
		);
	};

	const { buttonStep, type, value, ...rest } = props;

	return (
		<div className={`counter-wrapper counter-input ${theme}`}>
			<TextInput
				ref={inputElement}
				type='number'
				value={Number(props.value)}
				{...rest}
			/>
			<div className='counter-buttons'>
				<div
					className='counter-button plus'
					onMouseDown={doIncrementCounter}
				>
					+
				</div>
				<div
					className='counter-button minus'
					onMouseDown={doDecrementCounter}
				>
					-
				</div>
			</div>
		</div>
	);
};

Counter.defaultProps = {
	buttonStep: 1,
};

Counter.propTypes = {
	buttonStep: PropTypes.number,
	max: PropTypes.number,
	min: PropTypes.number,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default Counter;
