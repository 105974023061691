import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import './Slider.scss';
import Draggable from 'react-draggable';

let colors = {
	orange: '#ff5b45',
	pink: '#f476ff',
	purple: '#8976ff',
	blue: '#646eff',
	black: '#262626',
	og1: 'rgb(144, 144, 255)',
	og1b: 'rgba(144, 144, 255, .4)',
	og2: 'rgb(100, 100, 255)',
	og2b: 'rgba(144, 144, 255, .4)',
};

const SliderTrack = (props) => {
	let trackRef = useRef(null);
	const trackStyle = {
		background: `linear-gradient(90deg, ${colors.og1} 0%, ${colors.og2} ${
			props.value + 10
		}px, ${colors.og1b} ${
			props.value + 10
		}px, ${colors.og2b} 100%)`,
	};
	return (
		<div
			ref={trackRef}
			onMouseDown={(e) => props.jump(e.nativeEvent.offsetX)}
			style={trackStyle}
			className='slider-track'
			name={props.name}
		/>
	);
};

const SliderButton = (props) => {
	const [isDragging, setIsDragging] = useState(false);
	let draggableRef = useRef();

	const handleDragStart = () => {
		setIsDragging(true);
		props.onDragStart && props.onDragStart();
	};
	const handleDragStop = () => {
		setIsDragging(false);
		props.onDragStop && props.onDragStop();
	};

	return (
		<Draggable
			axis='x'
			bounds={{ left: props.min || 0, right: props.width - 20 }}
			onDrag={props.onDrag}
			position={{ x: props.left, y: 0 }}
			id='nut'
			handle='.slider-button'
			onStart={handleDragStart}
			onStop={handleDragStop}
			nodeRef={draggableRef}
		>
			<div
				id='butt'
				is-dragging={`${isDragging}`}
				className='slider-button'
				ref={draggableRef}
			/>
		</Draggable>
	);
};

const Slider = (props) => {
	const [width, setWidth] = useState(0);
	const [left, setLeft] = useState(0);

	let slideRef = useRef(null);

	let q = React.useMemo(() => {
		let w = width - 20;
		return props.max / w;
	}, [props.max, width]);

	const onChange = (val) => {
		let cVal = Math.round(val * q);
		props.onChange(props.name, cVal);
	};

	const handleDrag = (e, data) => {
		let x = data.x;
		setLeft(x);
		onChange(x);
	};

	useEffect(() => {
		let cVal = Math.round(props.value / q);
		setLeft(() => cVal);
	}, [props.value, q]);

	useEffect(() => {
		if (props.width) {
			setWidth(props.width);
		} else {
			let sl = slideRef.current;
			let w = sl.getClientRects()[0].width;
			setWidth(w);
		}
	}, [props.width]);

	const jump = (offset) => {
		let val;
		if (offset >= width - 20) {
			val = width - 20;
			setLeft(val);
			onChange(val);
		} else if (offset <= 10) {
			val = 0;
			setLeft(val);
			onChange(val);
		} else {
			val = offset - 10;
			setLeft(val);
			onChange(val);
		}
	};

	return (
		<>
			<div
				name={props.name}
				ref={slideRef}
				className={
					props.className ? `slider ${props.className}` : 'slider'
				}
			>
				<input
					type='range'
					className='slider__base-input'
					min={props.min || 0}
					max={props.max || 100}
					value={props.value}
					onChange={props.onChange}
					name={props.name}
				/>
				<SliderTrack name={props.name} value={left} jump={jump} />
				<SliderButton
					name={props.name}
					onDrag={handleDrag}
					left={left}
					width={width}
					min={props.min / q}
				/>
			</div>
		</>
	);
};

Slider.defaultProps = {
	min: 0,
	max: 200,
};

export default Slider;
