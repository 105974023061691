import React from "react";
import PropTypes from "prop-types";
import "./../Buttons.scss";

/////////////////////////////////////
////////// Regular Button //////////
///////////////////////////////////

const Button = (props) => {
	const { size, buttonClasses, buttonStyle, isDisabled, ...rest } = props;
	return (
		<button
			className={`button button--regular ${buttonStyle} ${buttonClasses} ${size}`}
			disabled={isDisabled}
			onClick={props.onClick}
			form={props.form}
			value={props.value}
			onSubmit={props.onSubmit}
			id={props.id}
			{...rest}
		>
			{props.label}
		</button>
	);
};

Button.defaultProps = {
	buttonStyle: "primary",
	isDisabled: false,
	size: "auto",
	label: "Button",
};

Button.propTypes = {
	buttonClasses: PropTypes.any,
	buttonStyle: PropTypes.string,
	form: PropTypes.any,
	id: PropTypes.any,
	isDisabled: PropTypes.bool,
	label: PropTypes.string,
	onClick: PropTypes.any,
	onSubmit: PropTypes.any,
	size: PropTypes.string,
	value: PropTypes.any,
};

export default Button;
