import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import "./Snackbar.scss";
import { DarkMode } from "../../App";

const Snackbar = (props) => {
	const theme = useContext(DarkMode);
	useEffect(() => {
		let show = props.show;
		let hide = props.hide;
		if (show) {
			setTimeout(() => {
				hide();
			}, 3000);
		}
	}, [props.show, props.hide]);

	return (
		<div className={`snackbar ${theme}`}>
			<p className='body'>{props.message}</p>
		</div>
	);
};

Snackbar.defaultProps = {};

Snackbar.propTypes = {
	hide: PropTypes.func,
	message: PropTypes.string,
	show: PropTypes.bool,
};

export default Snackbar;
